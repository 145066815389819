const bonusStatus = {
  10: 'Beantragt',
  11: 'Datenprüfung',
  15: 'Daten inkorrekt',
  20: 'Datenbestätigung<br>ausstehend',
  21: 'Datenbestätigung<br>Erinnerung',
  30: 'Daten bestätigt',
  40: 'Daten nicht<br>bestätigt',
  50: 'Kündigung',
  60: 'Antrag positiv',
  70: 'Antrag negativ',
  80: 'Bonus ausgefolgt',
}

const openAiFilter = {
  0: 'Alle',
  10: 'KI geprüft',
  20: 'Adresse nicht validiert',
  30: 'FIN nicht validiert',
  40: 'Kein elektrofahrzeug',
  50: 'Stampiglie nicht geprüft',
  60: 'Problem mit Datei',
  70: 'BESTÄTIGT-TEST nicht positiv',
  100: 'Noch nicht prozessiert',
}

const getLabelForBonus = status => {
  let label = ''
  Object.entries(bonusStatus).forEach(([key, value]) => {
    if (parseInt(key, 10) === status) {
      label = value
    }
  })

  return label
}

const getStatusColor = statusId => {
  let backgroundColor = 'rgba(40, 118, 251, 0.12)'
  let color = '#2876fb'

  if (parseInt(statusId, 10) === 10) {
    backgroundColor = 'rgba(40, 118, 251, 0.12)'
    color = '#2876fb'
  } else if (parseInt(statusId, 10) === 20) {
    backgroundColor = 'rgba(130, 134, 139, 0.12)'
    color = '#82868b'
  } else if (parseInt(statusId, 10) === 21) {
    backgroundColor = 'rgba(130, 134, 139, 0.12)'
    color = '#df4d12'
  } else if (parseInt(statusId, 10) === 25) {
    backgroundColor = 'rgba(130, 134, 139, 0.12)'
    color = '#df4d12'
  } else if (parseInt(statusId, 10) === 30) {
    backgroundColor = '#2876fb'
    color = '#ffffff'
  } else if (parseInt(statusId, 10) === 40) {
    backgroundColor = 'rgba(223, 77, 18, 0.12)'
    color = '#df4d12'
  } else if (parseInt(statusId, 10) === 50) {
    backgroundColor = '#df4d12'
    color = '#ffffff'
  } else if (parseInt(statusId, 10) === 60) {
    backgroundColor = '#7c9d2b'
    color = '#ffffff'
  } else if (parseInt(statusId, 10) === 70) {
    backgroundColor = '#df4d12'
    color = '#ffffff'
  } else if (parseInt(statusId, 10) === 80) {
    backgroundColor = '#000000'
    color = '#ffffff'
  }

  return `background-color:${backgroundColor}; color:${color};`
}

const connectorTypes = [
  { value: 1, text: 'Typ 1' },
  { value: 2, text: 'Typ 2' },
  { value: 3, text: 'CCS' },
  { value: 4, text: 'CHAdeMO' },
]

module.exports = {
  connectorTypes,
  bonusStatus,
  getLabelForBonus,
  getStatusColor,
  openAiFilter,
}
