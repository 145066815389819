<template>
  <div>
    <b-row>
      <b-col
        cols="8"
      >
        <b-card>
          <h4>Massenmailing Setup</h4>
          <br>
          <br>
          <h5>Input</h5>
          <v-select
            v-model="year"
            :options="yearDD"
            :clearable="false"
            label="text"
            placeholder="Yahr"
          />
          <br>
          <v-select
            v-model="partnerSelected"
            :options="partnerDD"
            :clearable="false"
            label="text"
            placeholder="Partner auswählen"
          />
          <br>
          <v-select
            v-model="statusSelected"
            :options="statusDD"
            :clearable="false"
            label="text"
            placeholder="Status auswählen"
          />
          <br>
          <br>
          <h5>Neue Status</h5>
          <v-select
            v-model="statusSelectedNew"
            :options="statusDD"
            :clearable="false"
            label="text"
            placeholder="Status auswählen"
          />
          <br>
          <b-button
            type="button"
            size="sm"
            variant="outline-primary"
            class="d-inline-block mr-1"
            style="margin-top:0px; min-width:150px;"
            @click="fetchBonus(true)"
          >
            Weiter
          </b-button>
        </b-card>
      </b-col>
      <b-col
        cols="4"
      >
        <b-card
          v-if="showBulkMailAction"
        >
          <h4>Massenmailing Start</h4>
          <br>
          <br>
          <b-button
            type="button"
            block
            size="sm"
            variant="outline-primary"
            class="d-inline-block mr-1"
            style="margin-top:0px; min-width:150px;"
            @click="fetchBonus(false)"
          >
            E-Mail senden ({{ totalBonus }})
          </b-button>
          <br>
          <br>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <h5>Massenmailing Vorschau</h5>
          <b-table
            ref="refBulkListTable"
            class="position-relative"
            :items="queryResult"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Keine passenden Einträge gefunden"
            style="min-height:700px"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(avatar)="data">
              <b-avatar
                :text="avatarText(fullName(data.item.lastname,data.item.firstname))"
                variant="success"
              />
              <b-link
                :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              />
            </template>

            <template #cell(street)="data">
              {{ data.value }}<br>
              {{ data.item.zip }} {{ data.item.city }}
            </template>

            <template #cell(email)="data">
              <feather-icon
                v-if="data.item.customertype==1"
                icon="UserIcon"
                size="16"
                class="align-middle text-body"
              />
              <feather-icon
                v-if="data.item.customertype==2"
                icon="ShieldIcon"
                size="16"
                class="align-middle text-body"
              />
              {{ data.value }}<br>
              <div v-if="data.item.customertype==2">
                <b-badge variant="light-primary">
                  {{ data.item.companytitle }}
                </b-badge>
              </div>
              {{ data.item.lastname }} {{ data.item.firstname }}
              <br>
              <span style="font-size: 13px">{{ data.item.address_street }}, {{ data.item.address_zip }} {{ data.item.address_city }}</span>
            </template>

            <template #cell(vin)="data">
              <b-link
                :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.value }}
              </b-link>
              <div
                v-if="data.item.backendcomment.length>0"
                v-b-tooltip.hover.top="data.item.backendcomment"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  size="16"
                  class="align-middle text-body"
                />
                &nbsp;
                {{ data.item.backendcomment.substring(0,35) }}
              </div>
            </template>

            <template #cell(currentstatus)="data">
              <b-badge
                :style="getStatusColor(data.value)"
              >
                {{ getStatusTitle(data.value) }}
              </b-badge>
            </template>

            <template #cell(datefrom)="data">
              {{ data.item.datefrom | moment("DD.MM.YYYY") }} -  {{ data.item.dateto | moment("DD.MM.YYYY") }}
            </template>

            <template #cell(lastName)="data">
              <b-link
                :to="{ name: 'bonus-detail', params: { bonusId: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.lastName }} {{ data.item.firstName }}
              </b-link>
            </template>

            <template #cell(lastopeneddate)="data">
              {{ data.item.lastopeneddate | moment("DD.MM. YYYY HH:mm") }}
              <br>
              {{ data.item.lastopenedbyusername }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'
import store from '@/store'
import bonusStoreModule from '../bonus/bonusStoreModule'

import { bonusStatus } from '../../../enums'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    vSelect,
  },
  setup() {
    const BONUS_STORE_MODULE = 'bonus-store-module'
    //  Register BonusStoreModule
    if (!store.hasModule(BONUS_STORE_MODULE)) store.registerModule(BONUS_STORE_MODULE, bonusStoreModule)

    //  Unregister BonusStoreModule
    onUnmounted(() => {
      if (store.hasModule(BONUS_STORE_MODULE)) store.unregisterModule(BONUS_STORE_MODULE)
    })

    const toast = useToast()

    const tableColumns = [
      { key: 'currentstatus', label: 'Status', thStyle: { width: '50px !important' } },
      { key: 'datefrom', label: 'Von - Bis', thStyle: { width: '200px !important' } },
      { key: 'street', label: 'LADEADRESSE', thStyle: { width: '200px !important' } },
      { key: 'email', label: 'Benutzer', thStyle: { width: '130px !important' } },
      { key: 'vin', label: 'FIN', thStyle: { width: '100px !important' } },
      { key: 'partner', label: 'Partner', thStyle: { width: '200px !important' } },
      { key: 'lastopeneddate', label: 'ZULETZT', thStyle: { width: '200px !important' } },
    ]

    const statusSelected = ref(0)
    const statusSelectedNew = ref(0)
    const totalBonus = ref(0)
    const status = ref(1)
    const sortBy = ref('lastOpenedDate')
    const isSortDirDesc = ref(true)
    const partnerSelected = ref('')
    const statusDD = ref([])
    const partnerDD = ref([])
    const yearDD = ref([])
    const queryResult = ref([])
    const showBulkMailAction = ref(false)

    const startYear = 2023
    const currentYear = new Date().getFullYear()
    const year = ref(currentYear)
    for (let i = startYear; i <= currentYear; i += 1) {
      const option = {
        text: `Jahr ${i}`,
        value: i,
      }
      yearDD.value.push(option)
    }

    Object.entries(bonusStatus).forEach(([key, value]) => {
      const option = {
        text: value,
        value: parseInt(key, 10),
      }
      statusDD.value.push(option)
    })

    store.dispatch(`${BONUS_STORE_MODULE}/fetchPartnerDD`)
      .then(response => {
        response.data.forEach(element => {
          const option = {
            text: element.title,
            value: element.value,
          }
          partnerDD.value.push(option)
        })
      })

    const fetchBonus = preflight => {
      let partner = ''
      if (typeof (partnerSelected.value.value) !== 'undefined') {
        partner = partnerSelected.value.value
      }

      let bonusStatusVal = 0
      if (typeof (statusSelected.value.value) !== 'undefined') {
        bonusStatusVal = statusSelected.value.value
      }

      let bonusStatusValNew = 0
      if (typeof (statusSelectedNew.value.value) !== 'undefined') {
        bonusStatusValNew = statusSelectedNew.value.value
      }

      let yearVal = currentYear
      if (typeof (year.value.value) !== 'undefined') {
        yearVal = year.value.value
      }

      store
        .dispatch(`${BONUS_STORE_MODULE}/bulkMailing`, {
          q: '',
          perPage: 1000000,
          page: 1,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          year: yearVal,
          status: status.value,
          vin: '',
          partner,
          bonusStatus: bonusStatusVal,
          email: '',
          preflight,
          bonusStatusNew: bonusStatusValNew,
        })
        .then(response => {
          const total = response.data.records
          const cars = response.data.list

          queryResult.value = cars
          totalBonus.value = total

          if (total > 0) {
            showBulkMailAction.value = true
          } else {
            showBulkMailAction.value = false
          }

          if (preflight === false) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Erfolg',
                text: 'Massenmailing im que',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })

            router.push('/settings/bulk-email-list')
          }
        })
        .catch(() => {
          console.log('do error on the screen')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              text: 'Fehler im Massenmailing que',
              icon: 'AlertIcon',
              variant: 'error',
            },
          })
        })
    }

    const getStatusTitle = statusId => bonusStatus[parseInt(statusId, 10)]

    const getStatusColor = statusId => {
      let backgroundColor = 'rgba(40, 118, 251, 0.12)'
      let color = '#2876fb'

      if (parseInt(statusId, 10) === 10) {
        backgroundColor = 'rgba(40, 118, 251, 0.12)'
        color = '#2876fb'
      } else if (parseInt(statusId, 10) === 20) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#82868b'
      } else if (parseInt(statusId, 10) === 21) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 25) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 30) {
        backgroundColor = '#2876fb'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 40) {
        backgroundColor = 'rgba(223, 77, 18, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 50) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 60) {
        backgroundColor = '#7c9d2b'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 70) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 80) {
        backgroundColor = '#000000'
        color = '#ffffff'
      }

      return `background-color:${backgroundColor}; color:${color};`
    }

    return {
      partnerDD,
      yearDD,
      statusDD,
      partnerSelected,
      statusSelected,
      statusSelectedNew,
      year,
      fetchBonus,
      tableColumns,
      queryResult,
      getStatusTitle,
      getStatusColor,
      showBulkMailAction,
      totalBonus,
    }
  },
}
//
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
