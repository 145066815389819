import axios from '@axios'

export default {
  namespaced: true,
  state: {
    carUpdateddAt: 0,
  },
  getters: {
    carUpdateddAt: state => state.carUpdateddAt,
  },
  mutations: {
    updateCarUpdatedAt: state => {
      state.carUpdateddAt = new Date().getTime()
    },
  },
  actions: {
    fetchBonus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('charging-station/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    bulkMailing(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('charging-station/list-bulk-mailing', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    bulkMailingHome(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('charging-station/bulk-mailing', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    exportBonus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('charging-station/export-csv-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportBonusBackoffice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('charging-station/export-csv-list-backoffice', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPartnerDD() {
      return new Promise((resolve, reject) => {
        axios
          .get('partner/list-frontend')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBonusDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/get-bonus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFile(ctx, { fileId, userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/file-repository/get-file/${fileId}/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveComment(ctx, {
      id, comment,
    }) {
      return new Promise((resolve, reject) => {
        const postData = { id, comment }
        axios
          .post('/car/backoffice-comment', postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    savePartnerComment(ctx, {
      id, comment,
    }) {
      return new Promise((resolve, reject) => {
        const postData = { id, comment }
        axios
          .post('/car/backoffice-partner-comment', postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStatus(ctx, {
      id, status,
    }) {
      return new Promise((resolve, reject) => {
        const postData = { id, status }
        axios
          .post('/car/backoffice-status', postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBonus(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/delete-bonus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reprocessOpenAi(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`openai/reprocess/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    historyOpenAi(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`openai/history/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reprocessRemoveOpenAi(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`openai/reprocess-remove/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
